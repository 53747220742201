.footer {
    background-color: var(--black);
    width: 100%;
    padding: 10vh 0;
}

.footer-texto,
.footer-links {
    color: var(--white);
    font-family: var(--text);
    font-size: 1rem;
}

.footer-links {
    color: var(--white);
}

.footer-container {
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
}

@media only screen and (min-width: 48rem) {

    .footer-container {
        width: 25rem;
    }

}
