@import '_variables';
@import '_scroll';
@import '_footer';

html {
    line-height: 1.5;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.header {
    height: 70vh;
    position: relative;
    background-color: var(--black);
}

.header-img {
    object-fit: cover;
    width: 100%;
}

.header-container {
    padding: 2rem 3rem;
    z-index: 2;
    width: 25rem;
    color: var(--white);
}

.header-title {
    font-size: 2rem;
    font-family: var(--title);
    margin: 0;
    text-transform: uppercase;

}

.chart-container {
    background-color: var(--white);
    width: 100%;
}

.grid-ao {
    background-color: var(--purple-light);
    padding-bottom: 2rem;
}

.grid-ao-element-white {
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
}

.grid-ao-black-container {
    width: 100%;
}

.ilustration-xavier {
    font-size: .75rem;
    font-family: var(--text);
    color: var(--black);
    display: block;
    margin-bottom: .25rem;
}

.grid-ao-black-image {
    width: 100%;
}

.chart-ao-text {
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
}

.chart-ao-text-title {
    font-size: 2rem;
    font-family: var(--title);
    margin: 0 auto;
    margin-top: 1.5rem;
    max-width: 25rem;
}

.chart-ao-text-paragraph {
    font-size: 1rem;
    font-family: var(--text);
    max-width: 25rem;
    margin: 1rem auto;
}

.asesinadas {
    /* stylelint-disable */
    position: -webkit-sticky;
    position: sticky;
    /* stylelint-enable */
    top: 94vh;
    z-index: 10;
    font-size: .75rem;
    font-family: var(--text);
    color: var(--white);
    padding: .5rem;
    margin: 0;
    margin-right: .5rem;
    background-color: var(--black);
    width: 100%;
    text-align: center;
    padding-bottom: 3rem;
}

.asesinadas-numero {
    font-size: 1.25rem;
    font-family: var(--number);
}

.container-scroll {
    background-color: var(--black);
}

@media only screen and (min-width: 48rem) {

    .header {
        height: 90vh;
    }

    .header-container {
        position: absolute;
        top: 40%;
        left: 20%;
        transform: translate(-50%, 30%);
    }

    .grid-ao {
        width: 100%;
    }

    .grid-ao-black-container {
        margin-top: -4rem;
        position: relative;
    }

    .grid-ao-element-white {
        max-width: 50rem;
    }

    .asesinadas {
        font-size: 1rem;
        background-color: transparent;
    }

    .asesinadas-numero {
        font-size: 2rem;
    }

}
