:root {
    --fontSystem: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, sans-serif;
    --monospace: 'SFMono-Regular', Consolas, Menlo, Courier, monospace;
    --black: #0B0B0B;
    --white: #fff;
    --yellow: #FAC92F;
    --purple: #170b38;
    --purple-light: #DEB9EC;
    --redstep: #c6eff5;
    --yellowalpha: rgba(250, 201, 47, .8);
    --title: 'Yeseva One', cursive;
    --text: 'Merriweather', serif;
    --number: 'Archivo Black', sans-serif;
}
