#scroll {
    position: relative;
    background-color: var(--black);
    padding: 2rem 0;
    grid-template-columns: 100%;
    display: grid;
}

.scroll-graphic {
    order: 1;
    z-index: 1;
    /* stylelint-disable */
    position: -webkit-sticky;
    position: sticky;
    /* stylelint-enable */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    top: 0;
}

.scroll-text {
    position: relative;
    order: 2;
    z-index: 2;
}

.step {
    margin: 0 auto;
    height: 95vh;
}

.step-subcontainer {
    background-color: var(--redstep);
    border-radius: 4px;
    padding: 1rem;
    text-align: center;
    max-width: 70%;
    width: max-content;
    margin: 0 auto;
}

.step-title {
    font-size: 1rem;
    font-family: var(--number);
    color: var(--black);
    padding: 0;
    margin-top: 0;
    margin-bottom: .25rem;
}

.step-number {
    font-size: 4rem;
    font-family: var(--number);
    line-height: 1;
}

.chart-ao {
    width: 100%;
    margin: 0;
    position: static;
}

.step-container {
    margin: auto;
}

@media only screen and (min-width: 60rem) {
    #scroll {
        padding: 5rem 0;
        grid-template-columns: 25rem 1fr;
    }

    .scroll-graphic {
        order: 2;
        position: static;
    }

    .scroll-text {
        order: 1;
    }

    .step-number {
        font-size: 12rem;
    }

    .step-subcontainer {
        padding: 3rem 1rem;
    }

    .step-title {
        font-size: 1rem;
    }

    .chart-ao {
        /* stylelint-disable */
        position: -webkit-sticky;
        position: sticky;
        /* stylelint-enable */
        top: 0;
    }

}
